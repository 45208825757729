.mat-drawer-container {
  // background: transparent !important;
}

span.material-icons {
  cursor: pointer !important;
}

@media (max-width: 720px) {
  .modal-content {
    width: 93% !important;
  }
}

.mat-stepper-horizontal,
.navy-dark .mat-stepper-vertical {
  background-color: #313b59 !important;
  // border-radius: 11px !important;
}

.closed {
  width: calc(100% - 16rem) !important;
  max-width: calc(100% - 16rem) !important;
  min-width: calc(100% - 16rem) !important;
}
.full {
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
}
.filters input,
.filters .mat-select {
    border-color: #56c8d8;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    height: 54px;
    width: 120px !important;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.09));
    background: transparent !important;
    padding: 0px 21px !important;
    display: flex !important;
    align-items: center !important;
}