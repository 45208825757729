.header-topnav {
  position: fixed;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 0;
  z-index: 999;

  .container {
    padding: 0;
  }

  .topbar-branding {
    float: left;
    height: 48px;
    padding: 8px;
    margin: 0 8px;

    img {
      height: 100%;
      width: auto;
    }
  }

  .topnav {
    display: flex;
    align-items: center;
  }

  .header-topnav-right {
    float: right;
    height: 48px;
    display: flex;
    align-items: center;
    padding-right: 0.67rem;
  }
}

@media (max-width: 959px) {
  .header-topnav-right {
    position: absolute;
    right: 6px;
    top: 0;
  }
}

[dir="rtl"] {
  .header-topnav {
    .topnav {
      flex-direction: row-reverse;
    }
  }
}

button.mat-button.mat-button-base.services {
  border-color: #56c8d8;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;

  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.15));

  background: #56c8d8
}
::ng-deep .mat-select-panel .mat-option{
  color: #475376 !important;
}