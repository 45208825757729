// COMMON CSS
/*---- Session (Sign in, sign up, forgot, lockscreen) -----*/
.page-wrap {
  display: flex;
  align-items: flex-start;
  padding: 4rem 1rem 0rem 1rem;
  height: 100%;
  background: #3d4866;
  position: fixed;
  width: 100%;
}

.session-progress {
  position: relative;
  bottom: -4px;
  z-index: 9999;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: none !important;
}

.session-form-hold {
  width: 100%;
  max-width: 50rem;
  flex: 1 1 32%;
  // margin: 0 0 0 76%;
}

.container-wrap {
  width: 100%;
}

@media screen and (max-width:1420px) and (min-width:720px) {
  .session-form-hold {
    max-width: 48rem !important;
  }

  .page-wrap {
    padding: 1rem 1rem 0rem 1rem !important;
  }

  
}

@media screen and (min-width:2200px) {
  .container-wrap {
    width: 92% !important;
  }

  .session-form-hold {
    width: 100%;
    max-width: 58rem !important;
    flex: 1 1 50% !important;
    // margin: 0 0 0 76%;
  }
  .signInImg img {
    height: 100% !important;
  }

  .session-form-hold>.mat-card {
    margin-left: -9rem !important;
  }

  .page-wrap {
    padding: 8rem 1rem 0rem 1rem !important;
  }
}

.session-form-hold>.mat-card {
  margin: 0;
  margin-left: -3rem
}

.container-card {
  height: 97vh;
  width: 100%;
  display: flex;
  padding: 0rem 6rem;
  align-items: flex-start;
  justify-content: flex-end;
  background: url('../../../../images/signIn.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.session-lockscreen {
  max-width: 320px;
}

.lockscreen-user {
  text-align: center;
  margin-bottom: 1rem;
  display: flow-root;
  overflow: hidden;
}

.lockscreen-face {
  max-width: 72px;
  max-height: 72px;
  border-radius: 50%;
  margin-bottom: .5rem;
}

.signInImg {
  flex: 1 1 50%;

  & img {
    width: 100%;
    height: 90%;
  }
}