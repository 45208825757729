 .mat-option {
    background: #fff !important;
    color: #10174c;
    border-bottom: 1px solid #4c4c4e29;
    height:  52px !important;
  }
.mat-select-panel{
  margin-top: 30px !important;
      background: #fff;
    box-shadow: 4px 4px 1px 1px #4c4c4e;
  }
  .mat-select-panel::-webkit-scrollbar-thumb {
    background: rgba(119, 120, 124, 0.5) !important;
    border-radius: 10px !important;
    /* height: 10% !important;
    max-height: 10%;
    min-height: 10%; */
  }
  
  .mat-select-panel::-webkit-scrollbar-track {
    background: #fff !important;
    border-radius: 8px !important;
  }
  .mat-select-panel::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
  }
  input.filter-select{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    line-height: 48px;
    height: 48px;
    padding: 0 16px;
    text-align: left;
    text-decoration: none;
    max-width: 100% !important;
    position: relative;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    border: none;
    border-bottom: 1px solid #e3e3e3;
    -webkit-tap-highlight-color: transparent;
}