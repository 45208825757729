/*-------------- Loading buttons --------------*/
.button-loading {
  .mat-button-wrapper {
    display: flex;
    align-items: center;
  }
}

.btn-spinner {
  width: 1em;
  height: 1em;
  background: transparent;
  border-radius: 50%;
  margin: 0 16px 0 0;
  border: 2px solid transparent;
  animation: btn-glow 1s ease infinite;
  ;
}

@keyframes btn-glow {
  0% {
    box-shadow: 0 0 0 .4em darken(#777676, 10%), 0 0 0 .1em darken(#777676, 10%);
    transform: rotate(360deg);
  }

  50% {
    border-top-color: #777676;
  }

  100% {
    box-shadow: 0 0 0 .4em darken(#777676, 10%), 0 0 0 3.6em transparent;
  }
}

img.exit {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30px;
  z-index: 11112;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

h1.head {
  font-size: 31px;
  font-weight: bold;
  line-height: 31px;
  font-style: normal;
  text-align: left;
  color: #56c8d8;
  margin-bottom: 30px;
}

.filter {
  display: flex;
  & input{
    height: 54px;
    min-height: 54px !important;
  }
  & textarea{
    height: 7em;
    min-height: 7em !important;
    padding-top: 16px;
  }
  & input, & textarea {
    flex: 1;
    
    border-color: #56c8d8;
    color: white;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.09));
    padding-left: 12px;
    background: transparent;
    margin-bottom: 25px;

    &::placeholder {
      color: #ffffff !important;
    }

    &.wrntyE {
      border: none !important;
      background: rgba(255, 47, 94, 0.18) !important;
      color: #ff2f5e !important;
    }

    &.wrntyS {
      border: none !important;
      background: rgba(32, 193, 219, 0.18) !important;
      color: #20c1db !important;
    }
  }

  // & label.main {
  //     flex: 1;
  //     height: 54px;
  //     border-color: #56c8d8;
  //     color: white;
  //     border-width: 1px;
  //     border-style: solid;
  //     border-radius: 8px;
  //     filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.09));
  //     padding-left: 12px;
  //     background: transparent;
  //     margin-bottom: 25px;
  //     display: flex;
  // }
  button.cancel {
    width: 40% !important;
    height: 54px !important;
    min-height: 54px !important;
    color: white !important;
    background-color: #ff8a48 !important;
    border: none !important;
    cursor: pointer;
    outline: none;
    text-align: center;
    font-size: 14px;
  }

  & mat-select {
    flex: 1;
    margin-bottom: 25px !important;
    height: 54px;
    min-height: 54px !important;
    border-color: #56c8d8;
    color: white;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.09));
    padding-left: 12px;
    background: transparent;
  }
}

button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-primary {
  height: 54px !important;
  font-family: sans-serif !important;
}

button.profile.mat-focus-indicator.mat-raised-button.mat-button-base.mat-primary {
  width: 100% !important;
  height: 63px !important;
  background: #20C1DB !important;
  border-radius: 8px !important;
  font-family: sans-serif !important;
  margin-top: 3em;
}

::ng-deep .filter mat-select.mat-select span.mat-select-placeholder {
  color: white !important;

}

::ng-deep .filter mat-select .mat-select-panel span.mat-option-text {
  color: #3d4866 !important;
}

::ng-deep .filter mat-select {
  display: flex !important;
  align-items: center !important;
  color: white !important;
  // width: 9.7em !important;
  margin-left: 6px;
}

::ng-deep .mat-select-value-text {
  padding: 10px !important;
}

@media screen and (min-width:1720px) {
  button.profile.mat-focus-indicator.mat-raised-button.mat-button-base.mat-primary {
    height: 96px !important;
  }
}
 .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #56C8D8 !important;
  height: 8px !important;
  border-radius: 8px !important;
  margin-top: 5px;
}
.mat-tab-label-content {
  margin-bottom: 15px !important;
}