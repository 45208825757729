@mixin ngx-datatable-theme($theme) {
  $color: map-get($theme, color);
  $background: map-get($color, background);
  $text-color: map-get(map-get($color, foreground), text);
  $disabled-text-color: map-get(map-get($color, foreground), disabled-text);
  $background-card: map-get($background, card);

  .ngx-datatable.material {
    background-color: #3d4866 !important;
    color: $text-color !important;
  }
  .ngx-datatable .datatable-header-cell,
  .ngx-datatable .datatable-body-cell,
  .page-count,
  .ngx-datatable .datatable-footer .datatable-pager a {
    color: $text-color !important;
    font-size: 14px !important;
  }
  .ngx-datatable.material datatable-row-wrapper.datatable-row-wrapper{
    padding: 10px 0px !important;
  }
  .ngx-datatable .datatable-header-cell {
    display: flex !important;
    align-items: center !important;  
    font-size: 14px !important;

  }
  span.datatable-header-cell-label.draggable{
    font-size: 14px !important;
  }
  .ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
    color: $disabled-text-color !important;
  }
  .ngx-datatable .datatable-header-inner{
    height: 100% !important;
    width: 100% !important;

  }
  .ngx-datatable.material .datatable-header {
    // background: map-get($background, background);
      height: 80px !important;
      border-radius: 8px !important;
      filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.09)) !important;
      background: #56c8d8 !important;
      width: 100% !important;

  }
//   .ngx-datatable.material .datatable-row-right{
//     display: none !important;
//   }
//   .datatable-row-center {
//     justify-content: space-between !important;
//     display: flex !important;
// }
// datatable-body-row.datatable-body-row.datatable-row-odd,
// datatable-body-row.datatable-body-row.datatable-row-even{
// width: 100% !important;
// }
// .datatable-row-center.datatable-row-group{
//   width: 100% !important;

// }
  .ngx-datatable.material datatable-scroller.datatable-scroll{
    width: 100% !important;
  }
  datatable-body-row.datatable-body-row , .datatable-row-center.datatable-row-group{
    width: 100% !important;
  }
  .ngx-datatable.material .datatable-body {
    background: transparent;
    border-color: #56c8d8;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px 8px 0px 0px;
    border-bottom: none;
    filter: drop-shadow(0px 3px 3px rgba(0,0,0,0.09 ));
    width: 100% !important;
  }
  .ngx-datatable.material .datatable-footer {
    background: transparent;
    border-color: #56c8d8;
    border-width: 1px;
    border-style: solid;
    border-radius: 0px 0px 8px 8px;
    border-top: none;
    filter: drop-shadow(0px 3px 3px rgba(0,0,0,0.09 ));
    overflow: hidden;
  }
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
  .ngx-datatable.material:not(.cell-selection)
    .datatable-body-row:hover
    .datatable-row-group {
    background: map-get($background, hover);
  }
}

@media screen and (max-width:1770px) {
  span.datatable-header-cell-label.draggable{
    font-size: 12px !important;
  }
  .ngx-datatable .datatable-header-cell {
    font-size: 12px !important;

  }
  .ngx-datatable .datatable-header-cell,
  .ngx-datatable .datatable-body-cell,
  .page-count,
  .ngx-datatable .datatable-footer .datatable-pager a {
    font-size: 12px !important;
  }
}
@media screen and (max-width:1919px) {
  
}
@media screen and (min-width:1920px) {
  
}