@each $colorName,
$matColor in $material-colors {
  $color500: map-get($matColor, 500);
  $foreground500: map-get(map-get($matColor, contrast), 500);

  .#{''+$colorName+''} {
    // background: url("../../images/businessman-touching-icon-infinity-global-network-connection.jpg") no-repeat center center fixed !important;
    background-size: cover !important;

    // Material CDK Table
    .mat-table {
      background: transparent !important;
    }

    .ngx-datatable.material *,
    .mat-table,
    .mat-cell,
    .mat-header-cell {
      color: $foreground500 !important;
    }

    .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
    .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
      background: $color500, 5;
    }
  }

  // Text color
  .text-#{''+$colorName+''} {
    color: $color500;
  }
  textarea,
input{
  &:focus-visible
  {outline: none;}
  &::placeholder{
    text-transform: capitalize;
  }
}
  // Sidebar backgrounds
  .sidebar-#{''+$colorName+''} {
    .branding {
      background: $color500 !important;
      margin-top: 20px;
      color: $foreground500 !important;
    }

    .navigation-hold {
      background: $color500 !important;

      a,
      .sidenav li.open>div>a>span:not(.menuitem-badge),
      .sidenav li.open>a>span,
      .icon-menu .mat-raised-button,
      .app-user .app-user-name,
      .app-user .app-user-role,
      .app-logo-text {
        color: $foreground500 !important;
      }

      mat-icon.mat-icon.notranslate.menu-caret.material-icons.mat-icon-no-color {
        color: #56C8D8 !important
      }

      a:hover {
        background: rgba(darken($color500, 20), .2) !important;
      }

      .app-user .app-user-photo,
      .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret) {
        border-color: $foreground500 !important;
        color: #00C1DB !important
      }

      .icon-menu .mat-raised-button {
        background: rgba($color500, .96) !important;
      }

      .app-user {
        // background: darken($color500, 5) !important;
      }

      li.open {

        // background: rgba($color500, .3) !important;
        >a {
          background: rgba(darken($color500, 20), .2) !important;
        }
      }

      .text-muted {
        color: desaturate($foreground500, 40) !important;
      }
    }
  }

  // Header backgrounds
  // .topbar-#{''+$colorName+''} {
  //   .topbar.mat-toolbar {
  //     background: $color500 !important;
  //     color: $foreground500 !important;
  //   }
  // }

  // Footer background
  .footer-#{''+$colorName+''} {
    .main-footer {
      background: $color500 !important;
      color: $foreground500 !important;
    }
  }


  // Generate classes for each color shades
  $contrastMap: map-get($matColor, contrast);

  @each $shade,
  $value in $matColor {
    @if $shade ==contrast {}

    @else {
      .#{''+$colorName+''}-#{$shade} {
        background-color: $value !important;
      }

      // Foreground text color
      .#{''+$colorName+''}-#{$shade}-fg {
        color: map-get($contrastMap, $shade) !important;
      }

      // Text color
      .text-#{''+$colorName+''}-#{$shade} {
        color: $value !important;
      }
    }

  }

}

#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  // z-index: 111;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // z-index: -1;
}