span.add {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 5px;
    width: 35px;
    // height: 49px;
    border-radius: 4px;
    background: rgba(86, 200, 216, 0.18);
    cursor: pointer;
}
span.viewSwitch {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 5px;
    // width: 35px;
    // height: 49px;
    border-radius: 4px;
    background: rgba(86, 200, 216, 0.18);
    cursor: pointer;
    color: #56c8d8;
    padding: 0rem 1.2rem;
    text-wrap: nowrap;
}
span.refresh {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 5px;
    width: 35px;
    // height: 49px;
    border-radius: 4px;
    background: rgba(111, 219, 32, 0.18);
    cursor: pointer;

}

span.download {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 5px;
    width: 35px;
    // height: 49px;
    border-radius: 4px;
    background: rgba(255, 47, 94, 0.18);
    cursor: pointer;

}