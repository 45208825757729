/*------- Profile ------*/
.profile-sidebar {
  box-sizing: border-box;
}

.profile-sidebar .propic {
  width: 100%;
  margin-bottom: 6px;
}

.profile-sidebar .hoverable {
  position: relative;
  text-align: center;
  margin: auto;
  display: block;
  cursor: pointer;
  width: 340px;
  height: fit-content;
  border-radius: 50%;
}

.profile-sidebar .hoverable img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profile-sidebar .hoverable .hover-text {
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.profile-sidebar .hoverable .background {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  border-radius: 50%;
  z-index: 1;
}

.profile-sidebar .hoverable:hover .hover-text {
  display: block;
}

.profile-sidebar .hoverable:hover .background {
  display: block;
}

.profile-sidebar #fileInput {
  display: none;
}

.profile-sidebar .propic img {
  width: 50%;
  height: auto;
  border-radius: 50%;
  border: 1px solid #20C1DB;
}

.profile-sidebar .profile-title .main-title {
  font-size: 1.5rem;
}

.profile-sidebar .profile-nav {
  margin-left: -24px;
  margin-right: -24px;
}

.profile-sidebar .profile-nav .mat-list-item .mat-icon {
  margin-right: 8px;
}

.timeline {
  position: relative;
  overflow: hidden;
}

.timeline::before {
  content: "";
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 24px;
  width: 1px;
  background-color: rgba(0, 0, 0, .12);
}

.timeline .timeline-item {
  width: 100%;
  overflow: hidden;
  margin-bottom: 24px;
}

.timeline .timeline-badge {
  float: left;
  position: relative;
  margin-right: 30px;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.timeline .timeline-badge img {
  max-width: 100%;
}

.timeline .timeline-badge .icon-badge {
  text-align: center;
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.timeline .timeline-body-top {
  margin-bottom: 8px;
}

.timeline .timeline-body {
  float: right;
  width: calc(100% - 78px);
}

.timeline .timeline-body-content img {
  border-radius: 4px;
  max-width: 100%;
}

.timeline .timeline-body-content> :last-child {
  margin-bottom: 8px;
}

[dir=rtl] {
  .profile-sidebar .profile-nav .mat-list-item .mat-icon {
    margin-right: 0;
    margin-left: 8px;
  }
}

.mat-tab-labels {
  display: flex;
  justify-content: start  !important;
  width: 100%  !important;
}
.mat-ripple.mat-tab-label {
  width: 11rem !important;
  font-size: 16px !important;
}
.mat-ripple.mat-tab-label.mat-focus-indicator.ng-star-inserted.mat-tab-label-active {
  color: #56C8D8 !important;
}

.profile-input {
  border: 1px solid #56C8D8;
  border-radius: 8px;
  position: relative;
  height: 63px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  padding-left: 10px;

  & img {
    // position: absolute;
    left: 10px;

  }

  & input {
    width: 100%;
    height: 63px;
    background: transparent;
    outline: none;
    box-shadow: none;
    border: none;
    color: #fff;
  }
}

@media screen and (max-width:1919px) {
 
}
@media screen and (min-width:1920px) {
  .profile-input {
    height: 96px !important;

    & input {
      height: 96px !important;
    }
  }
  .mat-ripple.mat-tab-label {
    width: 13rem !important;
    font-size: 20px !important;
  }
  .mat-tab-labels {
    display: flex;
    justify-content: center  !important;
    width: 100%  !important;
  }
}