// .mat-row, .mat-header-row {
//   min-width: 2800px;
//   width: 100%;
// }
// .mat-table{
//   overflow: auto;   
//   width: 1123px;
// }


// @media screen and (max-width: 960px) {
//   .mat-table {
//     border: 0;
//     vertical-align: middle;
//     padding: 0 24px;
//   }

//   .mat-table caption {
//     font-size: 1em;
//   }
  
//   .mat-table .mat-header-cell {
    
//     border: 10px solid;
//     clip: rect(0 0 0 0);
//     height: 1px;
//     margin: -1px;
//     padding: 0;
//     position: absolute;
//     width: 1px;
//   }
  
//   .mat-table .mat-row {
//     border-bottom: 5px solid #ddd;
//     display: block;
//   }
//   /*
//   .mat-table .mat-row:nth-child(even) {background: #CCC}
//   .mat-table .mat-row:nth-child(odd) {background: #FFF}
//   */
//   mat-cell:first-of-type, mat-cell:last-of-type, 
//   mat-footer-cell:last-of-type, mat-header-cell:last-of-type {
//     padding: 0;
//   }

//   .mat-table .mat-cell {
//     border-bottom: 1px solid #ddd;
//     display: block;
//     font-size: 1em;
//     text-align: right;
//     font-weight: bold;
//     height:30px;
//     margin-bottom: 4%;
//   }
//   .mat-table .mat-cell:before {
//     /*
//     * aria-label has no advantage, it won't be read inside a table
//     content: attr(aria-label);
//     */
//     content: attr(data-label);
//     float: left;
//     text-transform: uppercase;
//     font-weight: normal;
    
//     font-size: .85em;
//   }
//   .mat-table .mat-cell:last-child {
//     border-bottom: 0;
//   }
//     .mat-table .mat-cell:first-child {
//     margin-top: 4%;
//   }
// }
::ng-deep .navy .mat-chip.mat-standard-chip.ONLINE {
    background-color: rgb(7, 134, 56,0.2);
    color:  rgb(7, 134, 56);
}
::ng-deep .navy .mat-chip.mat-standard-chip.OFFLINE {
    background-color: rgb(63, 18, 90,0.2);
    color:  rgb(63, 18, 90);
}
::ng-deep  .navy .mat-chip.mat-standard-chip.LOST {
    background-color: rgba(90, 38, 18, 0.2);
    color:  rgb(90, 38, 18);
}
::ng-deep  .navy .mat-chip.mat-standard-chip.UN_REPAIR {
    background-color: rgba(79, 111, 179, 0.2);
    color:  rgb(79, 111, 179);
}
::ng-deep  .navy .mat-chip.mat-standard-chip.DIPOSED {
    background-color: rgba(150, 22, 96, 0.2);
    color:  rgb(150, 22, 96);
}
::ng-deep  .navy .mat-chip.mat-standard-chip.DEFECTIVE {
    background-color: rgba(133, 190, 27, 0.2);
    color:  rgb(133, 190, 27);
}
::ng-deep  .navy .mat-chip.mat-standard-chip.NEW {
    background-color: rgba(22, 24, 150, 0.2);
    color:  rgb(22, 24, 150);
}
::ng-deep  .navy .mat-chip.mat-standard-chip.REMOVED {
    background-color: rgba(148, 14, 14, 0.2);
    color:  rgb(148, 14, 14);
}

